import { valueOrDefault } from 'chart.js/helpers';

const defaultOptions = {
  line: {
    color: '#F66',
    width: 1,
    dashPattern: []
  },
  snap: {
    enabled: false,
  }
};

const CrosshairPlugin = {
  id: 'crosshair',

  afterInit(chart) {
    if (!chart.config.options.scales.x) {
      return;
    }

    const xScaleType = chart.config.options.scales.x.type;

    if (!['linear', 'time', 'category', 'logarithmic'].includes(xScaleType)) {
      return;
    }

    if (chart.options.plugins.crosshair === undefined) {
      chart.options.plugins.crosshair = defaultOptions;
    }

    chart.crosshair = {
      enabled: false,
      suppressUpdate: false,
      x: null,
      originalData: [],
      suppressTooltips: false,
      ignoreNextEvents: 0,
    };
  },

  getOption(chart, category, name) {
    return valueOrDefault(
      chart.options.plugins.crosshair[category]?.[name],
      defaultOptions[category][name]
    );
  },

  getXScale(chart) {
    return chart.data.datasets.length ? chart.scales[chart.getDatasetMeta(0).xAxisID] : null;
  },

  getYScale(chart) {
    return chart.scales[chart.getDatasetMeta(0).yAxisID];
  },

  afterEvent(chart, event) {
    if (chart.config.options.scales.x.length === 0) {
      return;
    }

    let e = event.event;

    const xScaleType = chart.config.options.scales.x.type;

    if (!['linear', 'time', 'category', 'logarithmic'].includes(xScaleType)) {
      return;
    }

    const xScale = this.getXScale(chart);

    if (!xScale) {
      return;
    }

    if (chart.crosshair.ignoreNextEvents > 0) {
      chart.crosshair.ignoreNextEvents -= 1;
      return;
    }

    chart.crosshair.suppressTooltips = e.stop;

    chart.crosshair.enabled = (
      e.type !== 'mouseout' &&
      (e.x > xScale.getPixelForValue(xScale.min) && e.x < xScale.getPixelForValue(xScale.max))
    );

    if (!chart.crosshair.enabled && !chart.crosshair.suppressUpdate) {
      if (e.x > xScale.getPixelForValue(xScale.max)) {
        // suppress future updates to prevent endless redrawing of chart
        chart.crosshair.suppressUpdate = true;
        chart.update('none');
      }
      return false;
    }

    chart.crosshair.suppressUpdate = false;

    chart.crosshair.x = e.x;

    chart.draw();
  },

  afterDraw(chart) {
    if (!chart.crosshair.enabled) {
      return;
    }

    this.drawTraceLine(chart);
    this.interpolateValues(chart);
    this.drawTracePoints(chart);

    return true;
  },

  beforeTooltipDraw(chart) {
    return !chart.crosshair.suppressTooltips;
  },

  drawTraceLine(chart) {
    const yScale = this.getYScale(chart);

    const lineWidth = this.getOption(chart, 'line', 'width');
    const color = this.getOption(chart, 'line', 'color');
    const dashPattern = this.getOption(chart, 'line', 'dashPattern');
    const snapEnabled = this.getOption(chart, 'snap', 'enabled');

    let lineX = chart.crosshair.x;

    if (snapEnabled && chart._active.length) {
      lineX = chart._active[0].element.x;
    }

    chart.ctx.beginPath();
    chart.ctx.setLineDash(dashPattern);
    chart.ctx.moveTo(lineX, yScale.getPixelForValue(yScale.max));
    chart.ctx.lineWidth = lineWidth;
    chart.ctx.strokeStyle = color;
    chart.ctx.lineTo(lineX, yScale.getPixelForValue(yScale.min));
    chart.ctx.stroke();
    chart.ctx.setLineDash([]);
  },

  drawTracePoints(chart) {
    for (let chartIndex = 0; chartIndex < chart.data.datasets.length; chartIndex++) {
      const dataset = chart.data.datasets[chartIndex];
      const meta = chart.getDatasetMeta(chartIndex);

      const yScale = chart.scales[meta.yAxisID];

      if (meta.hidden || !dataset.interpolate) {
        continue;
      }

      chart.ctx.beginPath();
      chart.ctx.arc(chart.crosshair.x, yScale.getPixelForValue(dataset.interpolatedValue), 3, 0, 2 * Math.PI, false);
      chart.ctx.fillStyle = 'white';
      chart.ctx.lineWidth = 2;
      chart.ctx.strokeStyle = dataset.borderColor;
      chart.ctx.fill();
      chart.ctx.stroke();
    }
  },

  interpolateValues(chart) {
    for (let chartIndex = 0; chartIndex < chart.data.datasets.length; chartIndex++) {
      const dataset = chart.data.datasets[chartIndex];
      const meta = chart.getDatasetMeta(chartIndex);

      const xScale = chart.scales[meta.xAxisID];
      const xValue = xScale.getValueForPixel(chart.crosshair.x);

      if (meta.hidden || !dataset.interpolate) {
        continue;
      }

      const data = dataset.data;
      const index = data.findIndex(o => o.x >= xValue);
      const prev = data[index - 1];
      const next = data[index];

      if (dataset.steppedLine && prev) {
        dataset.interpolatedValue = prev.y;
      } else if (prev && next) {
        const slope = (next.y - prev.y) / (next.x - prev.x);
        dataset.interpolatedValue = prev.y + (xValue - prev.x) * slope;
      } else {
        dataset.interpolatedValue = NaN;
      }
    }
  }
};

export default CrosshairPlugin;
