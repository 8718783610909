class ElementReference {

    constructor() {
        this.listenerId = 0;
        this.eventListeners = { };
    }

    focus (element) {
        if (element)
        {
            element.focus();
        }
    }

    focusFirst (element, skip = 0, min = 0) {
        if (element)
        {
            let tabbables = getTabbableElements(element);
            if (tabbables.length <= min)
                element.focus();
            else
                tabbables[skip].focus();
        }
    }

    focusLast (element, skip = 0, min = 0) {
        if (element)
        {
            let tabbables = getTabbableElements(element);
            if (tabbables.length <= min)
                element.focus();
            else
                tabbables[tabbables.length - skip - 1].focus();
        }
    }

    saveFocus (element) {
        if (element)
        {
            element['savedFocus'] = document.activeElement;
        }
    }

    restoreFocus (element) {
        if (element)
        {
            let previous = element['savedFocus'];
            delete element['savedFocus']
            if (previous)
                previous.focus();
        }
    }

    scrollToTop (element) {
      if (!element)
        return;

      element.scroll({ top: 0, behavior: "smooth" });
    }

    scrollToElementBySelector (selector) {
      document.querySelector(selector).scrollIntoView({ block: "nearest"})
    }

    // For a textarea, adjust the number of rows based on the content. This will cause the input to grow as the user types.
    adjustRowCount (element, dotnetObjectReference, currentCount) {
      if (!element || !dotnetObjectReference)
        return;

      let singleRowHeight = element.clientHeight / currentCount;
      let rowsThatFitInScrollHeight = Math.ceil(element.scrollHeight / singleRowHeight);

      // If more rows could fit within the scroll height, tell Blazor to update the rows property of the Input.
      if (rowsThatFitInScrollHeight !== currentCount) {
        dotnetObjectReference.invokeMethodAsync("UpdateRow", rowsThatFitInScrollHeight);
      }

      // If the input has been deleted, reset to one.
      if (element.value === "") {
        dotnetObjectReference.invokeMethodAsync("UpdateRow", 1);
      }
    }
}

const elementReference = new ElementReference();

window.elementRef = elementReference;

if (typeof globalThis === "object")
  globalThis.elementRef = elementReference;
