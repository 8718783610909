import 'chartjs-adapter-luxon'
import { Chart, registerables, Interaction } from 'chart.js';
import CrosshairPlugin from './trace.js';
import Interpolate from './interpolate.js';

// install plugins
Chart.register(...registerables, CrosshairPlugin);
Interaction.modes.interpolate = Interpolate;

const defaultLocale = 'en-US';

class ChartJsInterop {

  constructor() {
    this.ChartMap = new Map();
    this.LegendMap = new Map();
    this.HasRun = false;
  }

  setup(obj){
    console.log('CHARTJS - called Setup');
    if (typeof obj === 'undefined' || obj.id === undefined) return;

    const legendMapRef = this.LegendMap.get(obj.id);
    const ctx = document.getElementById(obj.id).getContext('2d');

    const tooltipLine = {
      id: 'tooltipLine',
      beforeDraw: chartReference => {

        if (chartReference.tooltip._active && chartReference.tooltip._active.length) {
          // User is actively hovering over the chart
          this.HasRun = false;
        } else {
          // User has moused off the chart - reset the legend values to most recent values
          const legendMapRef = this.LegendMap.get(this.LegendMap.keys().next().value);
          if (legendMapRef && !this.HasRun) {
            const datasets = chartReference.config.data.datasets;

            for (let i = 0; i < datasets.length; i++) {
              const dataset = datasets[i];
              const calculatePercentChange = legendMapRef.legendItemPercentChangeArray.includes(dataset.id);
              const finalVal = Number(dataset.data[dataset.data.length - 1].y);
              const percentChange = calculatePercentChange ? (finalVal - Number(dataset.data[0].y)) / Number(dataset.data[0].y) : 0;
              legendMapRef.dotNetObject.invokeMethodAsync(legendMapRef.legendUpdateMethod, dataset.id, finalVal, percentChange);
            }

            this.HasRun = true;
          }
        }
      }
    };

    // Apply the tooltip styling/interactions
    const tooltipPlugin = Chart.registry.getPlugin('tooltip');
    tooltipPlugin.positioners.myCustomPositioner = function (elements, position) {
      if (!elements.length) {
        return false;
      }
      return {
        x: position.x,
        y: elements[0].element.tooltipPosition().y
      };
    };

    let config = createConfig(obj.crosshairColor, obj.crosshairPattern, obj.timeUnit, obj.displayScales);
    config.options.plugins.tooltip = getChartTooltipObject(legendMapRef);
    config.plugins = [tooltipLine];

    //Apply localized currency formatting to the vertical label axis (Ex $5,000)
    config.options.scales.y.ticks.callback = function (value, index, values) {
      return getInternationalNumberFormat(legendMapRef.numFormatLocales, 'currency', legendMapRef.numFormatCurrency, 0, value);
    }

    let chartReference = new Chart(ctx, config);
    this.ChartMap.set(obj.id, chartReference);

    //Update the chart
    chartReference.update();
  }

  /// The parameter needs the properties id, timeUnit, data
  updateChartOptions(obj) {
    console.log('CHARTJS - called updateChartOptions');
    if (typeof obj === 'undefined' || obj.id === undefined) return;

    let chartReference = this.ChartMap.get(obj.id);
    const legendMapRef = this.LegendMap.get(obj.id);

    if (obj.data) {
      chartReference.data.datasets = obj.data.datasets;
      chartReference.data.labels = obj.data.labels;
    }

    if (obj.timeUnit && chartReference.options.scales.x.time.unit !== obj.timeUnit){
      chartReference.options.scales.x.time.unit = obj.timeUnit;
    }

    updateLegend(obj.id, chartReference, legendMapRef);

    chartReference.update();

    // Apply Gradient backgroundColor to Datasets
    for(let i=0; i < chartReference.data.datasets.length; i++){
      if(chartReference.data.datasets[i].backgroundColor === "gradient")
        chartReference.data.datasets[i].backgroundColor = getGradientFill(obj.id, chartReference.data.datasets[i].borderColor, chartReference);
    }

    chartReference.update();
  }

  //sets a map object of values to be referenced when updating the chart/legend values.
  registerDotNetInstance(obj) {
    console.log('CHARTJS - called registerDotNetInstance');
    if (typeof obj === 'undefined' || obj.id === undefined) return;

    //construct dotNet/legend update object
    const legendMapObj = {
      legendUpdateMethod: obj.legendUpdateMethodName,
      dotNetObject: obj.dotNetObj,
      legendItemPercentChangeArray: obj.legendItemPercentChangeArray,
      numFormatLocales: obj.numFormatLocales ? obj.numFormatLocales : null,
      numFormatCurrency: obj.numFormatCurrency ? obj.numFormatCurrency : null
    }

    this.LegendMap.set(obj.id, legendMapObj);
  }

  //cleanup and remove items from Maps/ reset hasRun flag
  cleanup(obj) {
    if (typeof obj === 'undefined' || obj.id === undefined) return;

    this.LegendMap.delete(obj.id);
    this.ChartMap.delete(obj.id);
    this.HasRun = false;
  }
}

// Formats currency based on locale
function getInternationalNumberFormat(locales, style, currency, maxDigits = 0, valueToFormat) {
  const clampedMaxDigits = Math.max(0, Math.min(maxDigits, 2));

  const options = {
    style,
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: clampedMaxDigits
  };

  const formatter = new Intl.NumberFormat(locales || defaultLocale, options);
  return formatter.format(valueToFormat);
}

// Get English ordinal suffix for the day of the month
function getOrdinalSuffix(day) {
  if (day > 3 && day < 21)
    return 'th';

  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

function getGradientFill(objId, baseColor, chartRef){
  const y = chartRef.scales.y;
  const min = y._range.min;
  const max = y._range.max;
  const ctx = document.getElementById(objId).getContext('2d');
  let gradient = ctx.createLinearGradient(0, chartRef.chartArea.bottom, 0, chartRef.chartArea.top);

  // Constants
  const alpha = 0.4;
  const minPercentage = 0.15;

  // Make colors
  const rgb = hexToRgb(baseColor);
  function getColor(a){ return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${a})`; }

  if (min >= 0) {
    gradient.addColorStop(minPercentage*2, getColor(0));
    gradient.addColorStop(1, getColor(alpha));
  } else if (max <= 0) {
    gradient.addColorStop(0, getColor(alpha));
    gradient.addColorStop(minPercentage*2, getColor(0));
  } else {
    const zeroPercentage = Math.abs(min)/(Math.abs(min)+max);
    const lower = Math.max(zeroPercentage - minPercentage, 0.2);
    const upper = Math.min(zeroPercentage + minPercentage, 0.8);

    gradient.addColorStop(0, getColor(alpha));
    gradient.addColorStop(lower, getColor(0));
    gradient.addColorStop(upper, getColor(0));
    gradient.addColorStop(1, getColor(alpha));
  }
  return gradient;
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

// This renders the vertical tooltip line on hover and any other tooltip values. For now, we're just showing the vertical line (both title and labels are returning an empty string).
function getChartTooltipObject(legendMapRef) {
  return {
    // Hide the tooltip for now
    display: true,
    borderColor: 'rgb(244, 245, 247)',
    borderWidth: 2,
    backgroundColor: 'rgb(255, 255, 255)',
    titleColor: 'rgb(107, 119, 140)',
    titleFont: {size: 12, weight: 400, lineHeight: 1.2},
    titleMarginBottom: 12,
    bodyColor: 'rgba(9, 30, 66)',
    bodyFont: {size: 12, weight: 400, lineHeight: 1.2},
    bodySpacing: 10,
    padding: 16,
    caretPadding: 10,
    boxHeight: 6,
    boxWidth: 6,
    boxPadding: 4,
    usePointStyle: true,
    // The tooltip is hidden so we don't need animation
    animation: false,
    mode: "interpolate",
    intersect: false,
    position: "average",
    callbacks: {
      title: function (a, d) {
        const date = new Date(a[0].element.x);
        const month = date.toLocaleString('en-US', {month: 'long'});
        const day = date.getDate();
        return `${month} ${day}${getOrdinalSuffix(day)}`;
      },
        labelColor: function (context) {
        return {
          borderColor: context.dataset.borderColor,
          backgroundColor: context.dataset.borderColor,
          borderRadius: 5,
        };
      },
      label: (d) => {
        if (legendMapRef) {
          const datasets = d.chart.data.datasets[d.datasetIndex];
          const calculatePercentChange = legendMapRef.legendItemPercentChangeArray.includes(datasets.id);
          const finalVal = Number(d.element.y);
          let percentChange = 0;
          if (calculatePercentChange) {
            const beginVal = Number(datasets.data[datasets.data.length-1].y);
            const difference = Number(finalVal - beginVal);
            percentChange = difference / beginVal;
          }
          legendMapRef.dotNetObject.invokeMethodAsync(legendMapRef.legendUpdateMethod, d.dataset.id, d.element.y, percentChange);
        }
        return (
          // This converts Y value into DatasetLabel + "   " + $Y with 2 decimals
          // Dataset Label (Ex. Realized Gains)
          d.chart.data.datasets[d.datasetIndex].label
          + '   '
          //Apply localized currency formatting to the vertical label axis (Ex $5,000)
          + getInternationalNumberFormat(legendMapRef.numFormatLocales, 'currency', legendMapRef.numFormatCurrency, 2, d.element.y)
        );
      }
    }
  }
}

function updateLegend(id, chartReference, legendMapRef) {
  if (id === undefined || chartReference === undefined || legendMapRef === undefined) return;

  let datasets = chartReference.data.datasets;
  for (let i = 0; i < datasets.length; i++) {
    let calculatePercentChange = legendMapRef.legendItemPercentChangeArray.includes(datasets[i].id);
    let finalVal = Number(datasets[i].data[datasets[i].data.length - 1].y);
    let percentChange = 0;
    if (calculatePercentChange) {
      let beginVal = Number(datasets[i].data[0].y);
      let difference = Number(finalVal - beginVal);
      percentChange = difference / beginVal;
    }
    legendMapRef.dotNetObject.invokeMethodAsync(legendMapRef.legendUpdateMethod, datasets[i].id, finalVal, percentChange);
  }
}

function createConfig(crosshairColor = '#505F79', crosshairPattern = [0, 100000000], timeUnit = 'month', displayScales = true){
  return {
    data: [],
    options: {
      animation: false,
      elements: {
        line: {
          borderWidth: 1.75
        },
        point: {
          radius: 0
        }
      },
      interaction: {
        intersect: false
      },
      layout: {
        padding: {
          left: 0,
          right: 0
        }
      },
      maintainAspectRatio: false,
      normalized: true,
      parsing: false,
      plugins: {
        crosshair: {
          line: {
            color: crosshairColor,
            dashPattern: crosshairPattern,
            width: 1
          }
        },
        decimation: {
          algorithm: 'min-max',
          enabled: true,
        },
        legend: {
          display: false
        },
        title: {
          display: false,
        }
      },
      responsive: true,
      scales: {
        x: {
          axis: 'x',
          border: {
            display: false
          },
          display: displayScales,
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: 40,
            color: '#A7B2C2',
            font: {
              family: 'Inter',
              size: 10,
              weight: 700
            },
            major: {
              enabled: false
            },
            maxTicksLimit: null,
            stepSize: 1
          },
          time: {
            unit: timeUnit
          },
          type: 'time'
        },
        y: {
          border: {
            display: false
          },
          display: displayScales,
          grid: {
            display: true,
            drawBorder: false
          },
          ticks: {
            autoSkip: false,
            autoSkipPadding: 0,
            color: '#808FA3',
            font: {
              family: 'Inter',
              size: 10,
              weight: 500
            },
            major: {
              enabled: false
            },
            maxTicksLimit: 5,
            stepSize: 10000
          },
          title: {
            display: false
          }
        }
      }
    },
    plugins: [],
    type: 'scatter'
  };
}

const chartJsInterop = new ChartJsInterop();

window.ChartJsInterop = chartJsInterop;

if (typeof globalThis === "object")
  globalThis.ChartJsInterop = chartJsInterop;
